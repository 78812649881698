import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ComponentLinks, FigmaEmbed } from '../common';
import { MonthPicker } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { useState, useMemo } from 'react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "monthpicker"
    }}>{`MonthPicker`}</h1>
    <ComponentLinks name={'MonthPicker'} storybook={'/?path=/story/calendar-calendar--month-picker'} vueStorybook={'/?path=/story/calendar-monthpicker--playground'} figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=30243%3A43619'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`MonthPicker – компонент для выбора месяца из списка.`}</p>
    <p>{`MonthPicker контролируемый компонент: для его работы следует передать пропы `}<inlineCode parentName="p">{`value`}</inlineCode>{` и `}<inlineCode parentName="p">{`onChange`}</inlineCode>{`.`}</p>
    <Playground __position={1} __code={'() => {\n  const [value, setValue] = useState(1)\n  return <MonthPicker value={value} onChange={setValue} />\n}'} __scope={{
      props,
      DefaultLayout,
      ComponentLinks,
      FigmaEmbed,
      MonthPicker,
      Playground,
      Props,
      useState,
      useMemo,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState(1);
        return <MonthPicker value={value} onChange={setValue} mdxType="MonthPicker" />;
      }}
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "отключенные-месяцы"
    }}>{`Отключенные месяцы`}</h3>
    <p>{`Для отключения определенных месяцев передайте проп `}<inlineCode parentName="p">{`disabledMonths`}</inlineCode>{`.`}</p>
    <Playground __position={2} __code={'() => {\n  const disabledMonths = useMemo(() => [2, 3], [])\n  const [value, setValue] = useState(1)\n  return (\n    <MonthPicker\n      disabledMonths={disabledMonths}\n      value={value}\n      onChange={setValue}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      ComponentLinks,
      FigmaEmbed,
      MonthPicker,
      Playground,
      Props,
      useState,
      useMemo,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const disabledMonths = useMemo(() => [2, 3], []);
        const [value, setValue] = useState(1);
        return <MonthPicker disabledMonths={disabledMonths} value={value} onChange={setValue} mdxType="MonthPicker" />;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={MonthPicker} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      